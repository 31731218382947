////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-thirdColor {
  background-color: rgb(0, 0, 0);
}
.bg-fourthColor {
  background-color: rgb(186, 35, 90);
}
.bg-fifthColor {
  background-color: rgb(230, 17, 93);
}
.bg-sixthColor {
  background-color: rgb(230, 17, 94);
}
.bg-seventhColor {
  background-color: rgb(186, 35, 90);
}
